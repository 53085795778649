<template>
    <div class="recognised">
        <div class="title">
            Recognised By
        </div>
        <div class="main-badge">
            <img src="./../../assets/imgs/pc/Recognised/TUDAWinnerBadges2022.png" class="prop-badge"/>
            <img src="./../../assets/imgs/pc/Recognised/startUp2022.png" class="com-badge"/>
            <img src="./../../assets/imgs/pc/Recognised/proptech2022.png" class="com-badge"/>
            <img src="./../../assets/imgs/pc/Recognised/design2022.png" class="com-badge"/>
            <img src="./../../assets/imgs/pc/Recognised/acs.png" class="acs-badge"/>
            <img src="./../../assets/imgs/pc/Recognised/stone.png" class="stone-badge"/>
        </div>
    </div>
</template>
<script>

export default ({

})
</script>
<style lang="scss" scoped>

.recognised{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // height: 82.32px;
}
.title{
  margin:0 0 20px 0;
  width: 84px;
  height: 18px;
  flex-grow: 0;
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  text-align: left;
  // text-align: left;
  color: #A9AEB8;
  white-space: nowrap;
}
.main-badge{
  display: flex;
  flex-direction: row;

}
.com-badge{
  margin:0 9px;
  width: 27.04px;
  height: 30px;
}
.prop-badge{
  margin:0 9px;
  width: 30px;
  height: 30px;
}
.acs-badge{
  margin:0 9px;
  width: 56px;
  height: 30px;
}
.stone-badge{
  margin:0 9px;
  width: 80px;
  height: 30px;
}
</style>
